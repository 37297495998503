.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.middleSection {
  min-height: calc(100vh - 550px);
  margin: 150px 0px 100px 0px;

}

.mainRow {
  padding: 18px 0 20px 0;
}

.headerContent {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}

.subHeader {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
}

.certificateCol {
  margin-bottom: 18px;
}

.certificateCol>h3 {
  margin-bottom: 16px;
}

.updateBtnDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.updateBtnDiv>button {
  background-color: var(--main-color-yellow);
  color: var(--text-color-black);
  font-weight: 500;
  padding: 10px 45px;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 1px 0px 5px 1px #ffffffb0;
}



/* For Container */
.container {
  max-width: 75%;
}


@media screen and (max-width:1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }
}


@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
  }

  .middleSection {
    margin: 110px 0px 70px 0px;

  }

  .page h3 {
    font-size: 28px;
  }

  .updateBtnDiv>button {
    padding: 9px 30px;
    font-size: 16px;
  }

  .page h1 {
    font-size: 32px;
  }
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .page h3 {
    font-size: 24px;
  }

  .page h1 {
    font-size: 28px;
  }


  .updateBtnDiv>button {
    padding: 8px 28px;
    font-size: 15px;
  }


}

@media screen and (max-width: 375px) {
  .page h3 {
    font-size: 20px;
  }

  .page h1 {
    font-size: 24px;
  }

}


/*  */