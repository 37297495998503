.footerSection {
  background: var(--light-gray);
  padding: 70px 0 0 0px;
}

.footerContainer {}

.footerContainer {}

.logo {
  cursor: pointer;
  width: 150px;
  height: auto;
}

.tagLine {
  font-size: 16px;
}

.footerSection .colume_one img {
  max-width: 80%;
  padding-bottom: 20px;
}

.footerSection .colume_one p {
  font-size: 14px;
  color: var(--text-color-gray);
  line-height: 1.8;
}

.footerSection h4.footerHeading {
  color: var(--main-color-red);
  font-weight: 600;
  padding-bottom: 11px;
}

.footerSection ul {
  list-style: none;
  padding: 0;
}

.footerSection ul>li>a {
  font-size: 15px;
  color: #2d2d2d;
  line-height: 36px;
  font-family: "Poppins";
  text-decoration: none;
}

.footerSection .colume_two,
.footerSection .colume_three,
.footerSection .colume_four {
  padding-left: 10px;
}

.footerSection .copyright {
  padding-top: 100px;
  padding-bottom: 10px;
}

.footerSection .copyright p.copyright {
  width: 70%;
  float: left;
  text-align: left;
}

.footerSection .copyright p.terms_condition {
  width: 30%;
  float: left;
  text-align: right;
}

.footerSection .copyright p.copyright_text {
  width: 70%;
  float: left;
  text-align: left;
  font-family: "Poppins";
}

.footerSection .copyright p.terms_condition a {
  color: black;
  font-family: "Poppins";
}

.footerSection .footerIcon {
  font-size: 26px;
  margin-right: 12px;
  color: #8d8d8d;
}

@media screen and (max-width: 991px) {

  .footerSection .colume_two,
  .footerSection .colume_three,
  .footerSection .colume_four {
    padding-left: 15px;
  }
}

@media screen and (max-width: 320px) {

  .footerSection .colume_two,
  .footerSection .colume_three,
  .footerSection .colume_four {
    padding-left: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerSection .colume_one img {
    max-width: 100%;
  }

  .footerSection .colume_one p {
    font-size: inherit;
  }

  .footerSection .footerIcon {
    font-size: 18px;
    margin-right: 9px;
  }

  .footerSection .copyright {
    padding-top: 70px;
  }

  .footerSection h4.footerHeading {
    margin-top: 24px;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .footerSection h4.footerHeading {
    margin-bottom: 0px;
  }

  .footerSection .copyright {
    padding-top: 34px;
  }

  .ContactUs .contact-sect-2 {
    padding: 268px 0;
  }

  .footerSection .copyright p.copyright_text {
    width: 100%;
    float: unset;
    text-align: center;
  }

  .footerSection .copyright p.terms_condition {
    width: 100%;
    float: unset;
    text-align: center;
  }

  .footerSection .colume_one img {
    max-width: 27%;
  }

  .footerSection .colume_one {
    text-align: center;
  }

  .footerSection .colume_two {
    text-align: center;
  }

  .footerSection .colume_three {
    text-align: center;
  }

  .footerSection .colume_four {
    text-align: center;
  }

  .footerSection .copyright p.copyright_text {
    width: 100%;
    text-align: center;
  }

  .footerSection {
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .footerSection .colume_one img {
    max-width: 55%;
  }
}

@media screen and (max-width: 320px) {
  .footerSection .copyright p.copyright_text {
    width: 100%;
    float: unset;
    text-align: center;
  }

  .footerSection .copyright p.terms_condition {
    width: 100%;
    float: unset;
    text-align: center;
  }

  .available-mechanics h1 {
    font-size: 32px;
    text-align: center;
  }

  .footerSection .colume_one img {
    max-width: 62%;
  }

  .footerSection .colume_two,
  .footerSection .colume_three,
  .footerSection .colume_four {
    padding-left: 0px;
  }

  .footerSection .colume_one img {
    max-width: 46%;
  }
}