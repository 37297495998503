.content {}

.content h4 {
    text-align: center;
    font-size: 25px;
}

.btnsBox {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.yesBtn {
    background-color: var(--main-color-yellow);
    padding: 7px 30px;

}

.noBtn {
    background-color: var(--main-color-red);
    padding: 7px 30px;
    margin-left: 20px;
}

/* For Header */
.iconDiv {
    margin-bottom: 6px;
}

.modalHeader {
    padding: 0px;
}

.header {

    font-size: 24px;
    color: var(--white-color);
}

.aCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;
    margin: auto;
}