.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
}

.heroSection {}

.container {
    max-width: 75%;
}

.imagesSection {
    margin: 70px 0px;
}


.footerSection {}

.noData>div {
    width: unset !important;
    height: 240px;

}

@media screen and (max-width:1440px) {
    .container {
        max-width: 85%;
    }
}

@media screen and (max-width:1024px) {
    .container {
        max-width: 90%;
    }
}


@media screen and (max-width:550px) {
    .container {
        max-width: 94%;
    }

}