.modalBody {
  padding: 24px;
}
.headerDiv > h3 {
  text-align: center;
  margin-bottom: 16px;
}
.BtnDiv {
  display: flex;
  justify-content: center;
  margin: 32px 0 0 0;
}
.BtnDiv > button {
  background-color: var(--main-color-red);
  padding: 12px 50px;
  border-radius: 12px;
}
.BtnDiv :first-child {
  margin-right: 16px;
}
.verifyCodeDiv > div {
  display: flex !important;
  justify-content: center;
}
