.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.bodyContainer {
  max-width: 60%;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 100px;
  box-shadow: 0px 0px 16px 6px rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.bodyContainer p {
  margin-bottom: 0px;
}

.userImagesContainer {
  position: relative;
  /* height: 400px !important; */
}

.coverImage {
  width: 100% !important;
  height: 100% !important;
  border-radius: unset !important;
}

.coverImageTagClass {
  object-fit: cover !important;
  object-position: center !important;
}

.profileImageContainer {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%);
}

.userDetailContainer {
  padding: 0px 3%;
}
.phoneNumber {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

.centerItems {
  text-align: center;
}

.noCertificate {
  font-size: 16px;
  font-weight: 600;
  color: gray;
}

.userNameText {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.SocialLinkContainer {
  margin: 15px 0px 20px 0px;
}

.socialIcon {
  margin-right: 10px;
  cursor: pointer;
}

.personalDetailRow {
  row-gap: 10px;
}

.personalDetailRow span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: gray;
}

.certificateAndServicesRow {
}

.certificateAndServicesRow h4 {
  margin: 30px 0px;
}

.certificateAndServicesRow ul li {
  list-style-type: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 10px;
}

.certificateAndServicesRow ul {
  padding-left: 0 !important;
}

.editbtn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 10px 40px;
  margin-top: 50px;
  font-size: 14px;
  margin-bottom: 30px;
}

/*  */

@media (max-width: 1440px) {
  .container {
    max-width: 85%;
  }

  .bodyContainer {
    max-width: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .bodyContainer {
    max-width: 80%;
  }

  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
    max-width: 85%;
  }

  .userImagesContainer {
    height: 300px !important;
  }
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 94%;
  }

  .userImagesContainer {
    height: 250px !important;
  }

  .certificateAndServicesRow h4 {
    font-size: 24px;
    margin: 15px 0px;
  }

  .certificateAndServicesRow h5 {
    font-size: 20px;
  }

  .certificateAndServicesRow ul li {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .personalDetailRow span {
    margin-left: 8px;
    font-size: 15px;
  }
}

@media screen and (max-width: 425px) {
  .certificateAndServicesRow h4 {
    font-size: 22px;
  }

  .certificateAndServicesRow h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 375px) {
  .userImagesContainer {
    height: 200px !important;
  }
}
