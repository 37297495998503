.card{

}
.card img{
    margin-bottom: 12px;
    height: 83px;
    width: auto;
}

.card h5{
    color: #e60000;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 7px;
}
.card p{
    margin-bottom: 0px;
    color: var(--text-color-gray);
    font-size: 12px;
    line-height: 1.8;
}
.card p::-webkit-scrollbar{
    width: 5px;
}
.card p::-webkit-scrollbar-thumb{
    background-color: var(--main-color-red);

}

@media (max-width: 768px){
    
  
    .card{
        text-align: center;
        max-width: 70%;
        margin: auto;
    }
  }