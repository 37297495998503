.page {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.available_mechanics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.available_mechanics h1 {
  color: var(--main-color-red);
  font-weight: bold;
}

.available_mechanics p {
  font-size: 20px;
  color: var(--text-color-gray);
  margin-bottom: 0px;
}

.fliter_div {
  display: flex;
  align-items: center;
}

.fliter_div h2 {
  font-size: 28px;
  color: #2d2d2d;
  font-weight: 600;
}

.filterIcon {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.mech_filter {
  margin-top: 20px;
}

.mech_filter h5 {
  font-size: 18px;
}

.filterBtn_div {
  margin-top: 20px;
}

.filter_btn {
  font-size: 14px;
}

.red_btn {
  background-color: var(--main-color-red);
}

.white_btn {
  border: 1px solid var(--main-color-red);
  color: var(--main-color-red);
  margin-left: 20px;
}

.mobileMachanics_AferSection {
  margin-top: 50px;
}

.background_with_image_Section {
  margin: 50px 0;
}

.OfferServices_Section .lower_text_heading {
  text-align: center;
  color: var(--main-color-red);
  position: relative;
  padding-bottom: 45px;
}

.OfferServices_Section .lower_text_heading > span {
  position: absolute;
  font-size: 130px;
  left: 18.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  /* top: -49%; */
  z-index: -1;
  display: flex;
}

.OfferServices_Section {
  padding: 50px 0;
}

.FrequientlyAsked_Section {
  background: #2d2d2d;
  padding: 50px 0;
}

.FAQ_head .upper_text_heading {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: white;
  font-weight: 400;
}

.FAQ_head .lower_text_heading {
  color: white;
  text-align: center;
  position: relative;
  padding-bottom: 45px;
}

.FAQ_head .lower_text_heading > span {
  left: 36%;
  z-index: 1;
  color: #70707026;
  position: absolute;
  font-size: 130px;
  text-transform: uppercase;
  top: -25%;
  display: flex;
}

.aboutUs_Section_div .upper_text_heading {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: black;
  font-weight: 400;
}

.aboutUs_Section_div .lower_text_heading {
  text-align: center;
  color: black;
  position: relative;
  padding-bottom: 45px;
}

.aboutUs_Section_div .lower_text_heading > span {
  position: absolute;
  font-size: 130px;
  left: 18.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  z-index: -1;
  display: flex;
}

.aboutUs_Section {
  margin-top: 50px;
}

.bgimgtyer {
  padding: 110px 50px;
}

.bgimgnetwork {
  padding: 160px 50px 100px 50px;
}

.join_btn {
  background-color: var(--main-color-yellow);
  color: black;
  font-size: 14px;
}

.Footer_Section {
  margin-top: 100px;
}

.aboutUs_row {
  align-items: center;
  padding: 0px 0 60px;
}

.aboutImg_sec img {
  transform: scale(1.3);
  position: relative;
  left: -10%;
}

.aboutSection_para {
  padding-left: 50px;
  padding-top: 40px;
}

.aboutSection_para > h5 {
  max-width: 80%;
  color: var(--main-color-red);
  text-transform: uppercase;
  line-height: 1.5;
  padding-bottom: 6px;
}

.aboutSection_para > p {
  color: #8d8d8d;
  font-size: 17.7px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.learn_more_btn {
  background-color: var(--main-color-yellow);
  color: var(--text-color-black);
  font-size: 14px;
}

.searchBtn {
  background-color: var(--main-color-red);
  height: 100%;
  width: 100%;
}

.Map_container {
  position: absolute;
  top: 30;
  right: 0;
  left: 0;
  z-index: 99999 !important;
}

.Map_section {
  padding-bottom: 60px;
}

.map_container {
  margin-top: -38%;
}

.container {
  max-width: 70%;
}

.custom_Container2 {
  max-width: 60%;
}

.custom_Container_faq {
  max-width: 55% !important;
}

.machanicCard_row {
  row-gap: 40px;
  margin-top: 50px !important;
}

.OfferServices_Section_row {
  row-gap: 40px;
}

.mobileMachanics_AferSection_row {
  row-gap: 40px;
}

.heroSection {
}

.NoDataContainer > div {
  width: unset;
}

@media (max-width: 1720px) {
  .map_container {
    margin-top: -33%;
  }
}

@media (max-width: 1600px) {
  .map_container {
    margin-top: -38%;
  }

  .container {
    max-width: 70%;
  }
}

@media (max-width: 1440px) {
  .container {
    max-width: 80%;
  }

  .map_container {
    margin-top: -32%;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 80%;
  }

  .map_container {
    margin-top: -30%;
  }

  .aboutUs_Section_div .lower_text_heading > span {
    position: absolute;
    font-size: 80px;
    left: 31.8%;
    top: -25%;
  }

  .OfferServices_Section .lower_text_heading > span {
    position: absolute;
    font-size: 80px;
    left: 31.8%;
    top: -25%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 992px) {
  .bgimgtyer {
    padding: 0px 17px 92px 17px;
  }

  .bgimgnetwork {
    padding: 65px 0px 44px 20px;
  }

  .available_mechanics h1 {
    font-size: 40px;
  }
  .map_container {
    height: 400px !important;
  }
}

@media screen and (max-width: 575px) {
  .heroSection {
    padding-bottom: 35%;
    padding-top: 100px !important;
  }

  .map_container {
    height: 300px !important;
  }

  .searchBtn {
    margin-top: 10px;
    padding: 8px 0;
    height: unset;
  }

  .available_mechanics h1 {
    font-size: 35px;
  }

  .available_mechanics p {
    font-size: 18px;
  }
}

@media screen and (max-width: 475px) {
  .heroSection {
    padding-bottom: 40%;
    padding-top: 80px !important;
  }

  .map_container {
    margin-top: -38%;
  }

  .available_mechanics h1 {
    font-size: 33px;
  }
}

@media screen and (max-width: 375px) {
  .heroSection {
    padding-bottom: 45%;
  }

  .map_container {
    margin-top: -42%;
  }

  .searchBtn {
    font-size: 15px;
  }

  .available_mechanics h1 {
    font-size: 30px;
  }
}
