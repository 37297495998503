.custom_Container {
  max-width: 70%;
  margin: auto;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.middleSection {
  min-height: calc(100vh - 700px);
  margin: 180px 0px 100px 0px;
}

.middleSection .container {
  max-width: 40%;
}


.inp_width {
  width: 100%;
}

.inp_div {
  margin-top: 20px;
}

.savebtn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 10px 40px;
  margin-top: 20px;
  font-size: 16px;
}

/* Container */

.container {
  max-width: 75%;
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .middleSection .container {
    max-width: 90%;
  }

}

@media screen and (max-width:767px) and (min-width:550px) {
  .container {
    max-width: 90%;
  }

  .middleSection .container {
    max-width: 70%;
  }


}

@media screen and (max-width:992px) and (min-width:767px) {
  .container {
    max-width: 90%;
  }

  .middleSection .container {
    max-width: 70%;
  }

}

@media screen and (max-width:1024px) and (min-width:992px) {
  .container {
    max-width: 90%;
  }

  .middleSection .container {
    max-width: 60%;
  }


}

@media screen and (max-width:1440px) and (min-width:1024px) {
  .container {
    max-width: 85%;
  }

  .middleSection .container {
    max-width: 55%;
  }

}