/* header and footer */
.custom_Container {
  max-width: 75%;
  margin: auto;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

/* header and footer */

.sectionImg {
  /* background-image: url("../../assets/images/Top.png"); */
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  width: 100%;
  padding: 170px 0 100px 0;
}

.mainContainer {
  max-width: 80%;
}

.chatMain {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}

.chatHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 38px;
  border-bottom: 2px dotted #dfdfdf;
}

.logoImg {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: inline-flex;
  flex-shrink: 0;
}

.logoImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  margin: 0 auto;
  border-radius: 50%;
}

.incomingMessage {
  background-color: #7674742e;
  padding: 20px;
  margin: 20px 0;
  color: var(--text-color-black);
  width: 70%;
  border-radius: 20px;
  margin-right: auto;
}


.outcomingMessage {
  background-color: var(--main-color-red);
  padding: 20px;
  margin: 20px 0;
  color: #fff;
  width: 70%;
  border-radius: 20px;
  margin-left: auto;
  text-align: end;
}


.time {
  color: #adb5bd;
}

.Incomingtime {
  color: #707070;
  float: right;
}

.inputSend {
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: #dfdfdf;
}

.sendMessage {
  position: relative;
}

.sendIcon {
  position: absolute;
  bottom: 9%;
  left: 96%;
}

.ChatRoom_Container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  /* padding: 20px 0; */
  overflow-y: auto;
  height: calc(100vh - 224px);
}

.ChatRoom_innerDiv {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
}

.ChatRoom_img_div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.ChatRoom_img_div img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.ChatRoom_text_div {
  margin-left: 12px;
  width: 100%;
}

.ChatRoom_innertext_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatBoxUserName,
.roomsDataUserName {
  text-transform: capitalize;
}

.ChatRoom_innertext_div h5 {
  font-size: 17px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 2px;
}

.ChatRoom_innertext_div p {
  font-size: 12px;
  color: var(--red-color);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ChatRoom_innertext_div_para {
  font-size: 15px;
  color: #7a7a7a;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.isChatActive {
  background-color: #ffebec;
}

/* */
.inbox {
  background: var(--white-color);
  box-shadow: 0px 0px 11px #2222221c;
  border-radius: 20px;
  height: 100%;
  position: relative;
}

.chattingDiv {
  /* padding: 38px 38px 100px 38px;
  height: calc(100vh - 318px); */

  padding: 38px 38px 20px 38px;
  height: calc(100vh - 400px);
  overflow-y: auto;

  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  /* overflow: scroll; */
}

/* width */
.chattingDiv::-webkit-scrollbar,
.ChatRoom_Container::-webkit-scrollbar {
  width: 6px;
  border-radius: 24px;
}

/* Track */
.chattingDiv::-webkit-scrollbar-track,
.ChatRoom_Container::-webkit-scrollbar-track {
  border-radius: 24px;
  margin: 30px 0px;
  margin-bottom: 50px;
}

/* Handle */
.chattingDiv::-webkit-scrollbar-thumb,
.ChatRoom_Container::-webkit-scrollbar-thumb {
  background-color: var(--red-color);
  border-radius: 10px;
}

.noRoomSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 336px);
}

.noRoomInnerSelected {
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sendBtnDiv {
  position: absolute;
  width: calc(100% - 76px);
  bottom: 32px;
  left: 38px;
}

.mainContainer p {
  margin: 0;
}

.SendInputMainContainer {
  background-color: #e1e1e1;
}

.sendInput {
  font-size: 16px !important;
  color: #000 !important;
}

.SendInputBtnContainer {
  background-color: transparent !important;
}

.SendInputBtnContainer:hover,
.SendInputBtnContainer:active+.SendInputBtnContainer,
.SendInputBtnContainer:checked+.SendInputBtnContainer,
.SendInputBtnContainer.active,
.SendInputBtnContainer:active,
.show>.SendInputBtnContainer.dropdown-toggle {
  background-color: transparent !important;
}

.SendInputBtnContainer:active+.SendInputBtnContainer:focus,
.SendInputBtnContainer:checked+.SendInputBtnContainer:focus,
.SendInputBtnContainer:focus,
.SendInputBtnContainer.active:focus,
.SendInputBtnContainer:active:focus,
.show>.SendInputBtnContainer.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.loadMoreButtonDiv {
  display: flex;
  justify-content: center;
}

.loadMoreButtonDiv button {
  background-color: var(--main-color-red);
  color: #fff;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 5px;
}

.noData {
  height: 100%;
}

.noData div {
  width: unset !important;
  height: 240px;
}

/*  */


@media (max-width: 1600px) {
  .custom_Container {
    max-width: 70%;
  }
}

@media (max-width: 1440px) {
  .custom_Container {
    max-width: 90%;
  }
}


/* @media (max-width: 1280px) {
  .custom_Container {
    max-width: 90%;
  }
}

@media (max-width: 1024px) {
  .custom_Container {
    max-width: 90%;
  }
} */