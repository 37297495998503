.page {
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.certificateCol {
  margin-bottom: 16px;
}

.certificateCol > h3 {
  margin-bottom: 16px;
}

.mBlock20 {
  margin-bottom: 20px;
}

.heroSection {
  height: 85vh;
}

.profileSection {
  margin-top: 150px;
  margin-bottom: 6%;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 6% !important;
  font-size: 53px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.card {
  padding: 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 15px;
}

.card p {
  font-size: 18px;
  color: #e60000;
}

.termsAndCondition {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.submitBtn {
  background-color: #fecb00;
  color: #000;
  padding-inline: 40px;
  padding-block: 15px;
}

.submitBtnDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.userImagesContainer {
  height: 400px;
  margin-bottom: 80px;
  position: relative;
}

.coverEditBtn {
  right: 20px !important;
  bottom: -10px !important;
}

.coverImage {
  width: 100% !important;
  height: 100% !important;
  border-radius: unset !important;
  border-radius: 10px !important;
}

.coverImageTagClass {
  object-fit: cover !important;
  object-position: center !important;
  border-radius: inherit !important;
}

.userImagesContainer .profile_div {
  position: absolute;
  bottom: -0%;
  left: 50%;
  transform: translate(-50%, 50%);
}

/* For PDFS */
.p70 {
  padding-bottom: 70px;
}

/*  */
.publicDocuments_div {
  margin-top: 30px;
}

.privateDocuments_div {
  margin-top: 30px;
}

.public_text {
  font-weight: 800 !important;
}

.doc_outter_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 15px 20px;
}

.doc_div {
  display: flex;
  align-items: center;
}

.file_text {
  margin-left: 10px;
  margin-bottom: 0;
}

.greyText {
  color: gray;
}

.doc_para_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.para_text {
  margin: 0;
}

.c_p {
  cursor: pointer;
}

.choose_btn {
  color: var(--main-color-red);
  border: 1px solid var(--main-color-red);
  border-radius: 5px;
  font-size: 14px !important;
  padding: 10px 40px;
  margin-top: 20px;
  background-color: white;
}

.certificatesBox {
  border: 1px solid var(--very-light-gray);
  width: 100%;
  padding: 20px;
  display: flex;
}

.certificatesBoxCol {
  background-color: var(--light-gray);
  border-radius: 10px;
  margin-top: 20px;
  overflow-x: scroll;
  position: relative;
}

.certificatesBoxCol::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.certificatesBoxCol::-webkit-scrollbar-thumb {
  background: rgb(173, 172, 172);
  border-radius: 10px;
}

.certificatesBoxCol::-webkit-scrollbar-thumb:hover {
  background: grey;
}

/* When we have no pdf */
.uploadPdfIconContainer {
  width: 80px;
  height: auto;
}

.uploadPdfIconContainer img {
  width: 100%;
  height: 100%;
}

.pdf_btn label {
  background-color: var(--main-color-red);
  border-radius: 10px;
  padding: 10px;
  color: var(--white-color);
  font-weight: 600;
  font-size: 16px;
}

.btn_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.uploadMoreBtnBox {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.uploadBtn {
  background-color: var(--main-color-red);
  font-size: 15px;
  padding: 7px 20px;
}
.labelText {
}

.locationMainContainer {
  position: relative;
}

.locationInput input {
  padding: 15px 20px;
}

/*  */

/* For Queries */

@media screen and (max-width: 1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
    font-size: 40px;
  }

  .heading2 span {
    font-size: 66px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
  }

  .heading2 {
    font-size: 35px;
  }

  .page h3 {
    font-size: 28px;
  }

  .submitBtn {
    padding: 9px 30px;
  }

  .page h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 94%;
  }

  .page h3 {
    font-size: 24px;
  }

  .page h2 {
    font-size: 28px;
  }

  .submitBtn {
    padding: 8px 28px;
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }

  .page h3 {
    font-size: 20px;
  }

  .page h2 {
    font-size: 24px;
  }
}

/*  */
