.mainModalDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.imgDiv > img {
  width: 115px;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}
.ConfirmBtnDiv {
  display: flex;
  justify-content: center;
}
.ConfirmBtn {
  font-size: 18px;
  color: #fff;
  background-color: #e60000;
  border-radius: 14px;
  text-align: center;
  padding: 10px 24px;
}
.title {
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  margin: 0 0 14px 0;
  font-weight: bold;
}
.subTitle {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  margin: 0 0 22px 0;
}
