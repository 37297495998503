.page {}

.container {
  max-width: 80%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.heroSection {
  height: 85vh;
}

.faqSection {
  margin-bottom: 6%;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 6% !important;
  font-size: 53px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.card {
  padding: 30px 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 15px;
  margin-bottom: 32px;
  cursor: pointer;
}

.card p {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}

.selectedPolicyContent {
  color: var(--main-color-red);
}

.description p {
  margin: 0;
}

@media (max-width: 1440px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
    font-size: 40px;
  }

  .heading2 span {
    font-size: 66px;
  }

}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
  }

  .heading2 {
    font-size: 35px;
  }
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

}


@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }
}