.headerClass {
  margin: 14px 0 0 0;
  padding: 16px 24px;
  border: none !important;
}
.headerClass h3 {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.modalBodyDiv {
  padding: 0 24px 24px 24px;
}
.addNewCardlabel {
  font-size: 14px;
  color: #e60000;
  margin-left: 8px;
  margin-bottom: 0;
}
.debitCreditLabel {
  font-size: 15px;
  margin: 0;
  font-weight: lighter;
  margin-left: 8px;
  margin-bottom: 0;
  color: #000;
}
.addNewCardDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px 8px;
}
.addNewCardDiv > div {
  display: flex;
  align-items: center;
}
.noCardsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px 0;
  align-items: center;
}
.noCardsDiv img {
  width: 70px;
  height: auto;
}
.noCardsDiv > h6 {
  color: rgba(0, 0, 0, 0.5);
  margin: 8px 0;
}
.buyPackageBtnDiv {
  display: flex;
  justify-content: center;
}
.buyPackageBtn {
  font-size: 18px;
  color: #fff;
  background-color: #e60000;
  border-radius: 10px;
  text-align: center;
}
@media (max-width: 390px) {
  .addNewCardlabel {
    font-size: 12px;
    margin-left: 4px;
  }
  .debitCreditLabel {
    font-size: 12px;
    margin-left: 4px;
  }
  .modalBodyDiv {
    padding: 0 16px 24px 16px;
  }
  .headerClass {
    padding: 16px 16px;
  }
  .headerClass h3 {
    font-size: 24px;
  }
  .addNewCardDiv {
    padding: 6px 0;
  }
}
