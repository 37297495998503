.c_p{
    cursor: pointer;
}
.t_t_c{
  text-transform: capitalize;
}
.modal_container{
    padding: 20px ;
}
.profileSubContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
  .profile {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
  }
  .profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .send_para{
    text-align: center;
   margin-top: 20px;
   margin-bottom: 10px;
    font-size: 16px;
    color: gray;
  }
  .textArea_div textarea{
    width: 100%;
    padding: 20px;
    margin-bottom: 10px ;
  }
  .send_btn_div{
    text-align: center;
  }
  .send_btn{
    padding: 7px 30px;
    border: none;
    background-color: #FFCC00;
    color: black;
    font-weight: bold;
    border-radius: 10px;
  }
