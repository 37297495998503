.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.cardsSection {
    margin: 90px 0px;
}

.skeleton {
    height: 200px;
    border-radius: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.noData>div {
    width: unset !important;
    height: 260px;

}

.container {
    max-width: 75%;
}

@media (max-width: 1440px) {
    .container {
        max-width: 85%;
    }

}

@media screen and (max-width:1024px) {
    .container {
        max-width: 90%;
    }
}



@media screen and (max-width:550px) {
    .container {
        max-width: 94%;
    }

    .noData h3 {
        font-size: 22px !important;
    }

}