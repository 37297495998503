.NotificationScreen {
  width: 100%;
  height: 100%;
  min-height: 100vh;

}

.noData {
  min-height: 80vh;
  height: 350px;
  /* padding-top: 89px; */
  /* padding: 0px; */
}

.mainContainer {
  max-width: 90%;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.middleSection {}

.notificationCol {
  margin-bottom: 32px;
}

/* notifications */
.ImgDiv img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.HeaderNotificationInnerDiv {
  display: flex;
  border-bottom: 1px solid var(--text-color-gray);
  padding: 0px 0px 24px 0px;
  cursor: pointer;
  position: relative;
}

.contentDiv {
  margin-left: 18px;
}

.contentDiv h6 {
  margin-bottom: 8px;
  font-size: 24px;
}

.contentDiv p {
  color: var(--text-color-gray);
  font-size: 18px;
}

.viewAll {
  text-align: center;
  color: var(--main-color);
  cursor: pointer;
}

.header {
  border: 1px solid var(--main-color);
  box-shadow: 0px 3px 26px #0000001a;
}

.timeDiv {
  position: absolute;
  right: 18px;
  color: var(--text-color-black);
  font-weight: 500;
}

.LoaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 45px);
}

@media (min-width: 1440px) {
  .mainContainer {
    max-width: 80%;
  }
}