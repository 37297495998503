.section {
}

.section .heroImg {
  /* padding: 160px 50px 100px 50px; */
  background-size: 100% 100%;
}

.title {
  margin-bottom: 0;
  color: var(--white-color);
  font-size: 42px;
}

.description {
  color: var(--white-color);
  padding: 14px 0px;
  max-width: 90%;
  line-height: 1.6;
}

.btnClass {
  font-size: 16px !important;
  background-color: var(--main-color-yellow) !important;
  color: var(--text-color-black) !important;
}

.content {
  padding-top: 80px;
}

@media (max-width: 1600px) {
  .content {
    padding-top: 0px;
  }
}
@media (max-width: 1080px) {
  .title {
    font-size: 36px;
  }
}
@media (max-width: 800px) {
  .description {
    max-width: 100%;
  }
  .title {
    font-size: 30px;
  }
}
