.img_div{
  width: 200px;
  height: 100px;
  margin: auto;
}
.img_div img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center ;
}
.modal_container{
  text-align: center;
  padding: 20px;
}
.text_div{
  margin: 30px 0;
}
.done_btn_div button{
  padding: 7px 30px;
  border: none;
  background-color: #FFCC00;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  /* margin-top: 10px; */
}