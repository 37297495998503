.page {
  overflow-x: hidden;
}


.background_with_image_Section {
  margin: 50px 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.lower_text_heading {
  text-align: center;
  color: var(--main-color-red);
}


.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 45px;
  font-weight: 700;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  bottom: 0%;
  z-index: -1;
  transform: translateX(-50%);
  white-space: nowrap;
}

.aboutUs_Section_div .lower_text_heading {
  position: relative;
  padding-bottom: 45px;
}

.aboutUs_Section_div .lower_text_heading>span {
  position: absolute;
  font-size: 130px;
  left: 18.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  z-index: -1;
  display: flex;
}

.aboutUs_Section {
  margin-top: 50px;
}

.TypesMechanic_Section {
  text-align: center;
  margin-top: 20px;
}

.TypesMechanic_Section .lower_text_heading {
  margin-bottom: 40px;
}

.MechanicHub_Section h1 {
  text-align: center;
  color: var(--main-color-red);
  margin-top: 50px;
  margin-bottom: 50px;
}

.MechanicHub_Section p {
  text-align: center;
  color: var(--text-color-gray) !important;
  margin-bottom: 50px;
}

.bgimgnetwork {
  padding: 160px 50px 100px 50px;
}

.Footer_Section {
  margin-top: 100px;
}

.aboutUs_row {
  align-items: center;
  padding: 0px 0 60px;
}

.aboutSection_para>h3 {
  max-width: 70%;
  color: #e60000;
  text-transform: uppercase;
  line-height: 1.5;
  padding-bottom: 6px;
}

.aboutSection_para>p {
  color: #8d8d8d;
  font-size: 17.7px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.MechanicTypeCard_row {
  row-gap: 30px;
}

/*  */
.container {
  max-width: 75%;
}

@media screen and (max-width:1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }

  .page h1 span {
    font-size: 86px;
  }
}


@media screen and (max-width:992px) {

  .aboutSection {
    padding-top: 79px !important;
  }

  .page h1 {
    font-size: 45px;
  }

  .page h1 span {
    font-size: 76px;
  }

  .aboutSection_para>h3 {
    max-width: 100%;
  }

}

@media screen and (max-width:768px) {

  .page h1 {
    font-size: 40px;
  }

  .page h1 span {
    font-size: 66px !important;
  }

  .aboutSection_para>h3 {
    margin-top: 20px;
  }

  .aboutImg_sec {
    text-align: center;
  }

}


@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .aboutSection {
    padding-top: 47px !important;
    padding-bottom: 44% !important;
  }


  .page h1 span {
    font-size: 44px !important;
  }

  .aboutSection_para>h3 {
    font-size: 22px !important;
    text-align: center;
  }

  .aboutSection_para>p {
    font-size: 16px !important;
  }

}

@media (max-width: 420px) {
  .bgimgnetwork {
    padding: 113px 10px 33px 1px;
  }

  .page h1 {
    font-size: 30px;
  }

  .bgimgnetwork h2 {
    font-size: 20px !important;
  }

  .bgimgnetwork p {
    font-size: 14px !important;
  }

  .join_btn {
    padding: 7px;
    font-size: 13px !important;
  }
}

@media (max-width: 375px) {
  .aboutSection {
    padding-bottom: 60% !important;
  }
}