.header {
  padding: 0px 0px 10px 0px !important;
}

.bgWhite {
  background-color: var(--white-color);
  box-shadow: 3px -4px 3px 5px rgb(0 0 0 / 10%);
}

.boxShadow {
  box-shadow: 3px -4px 3px 5px rgb(0 0 0 / 10%);
}

.bgWhite .nabarLinks {
  color: var(--text-color-black) !important;
}

.bgWhite .navActive {
  color: var(--main-color-yellow) !important;
}

.bgWhite .iconBlack {
  color: var(--black-color) !important;
}

.iconBlack {
  color: var(--black-color);
}

.iconWhite {
  color: var(--white-color);
}

.navbarContainer {
  padding-top: 15px;
}

.wrapper {
  /* font-family: "Inter", sans-serif; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

svg {
  display: inline-block;
  vertical-align: top;
}

.viewProfile {
  color: var(--black-color);
  font-size: 15px;
}

.imageContainer {
  width: 100px !important;
  height: auto;
  cursor: pointer;
  /* background: aliceblue; */
}

.headerProfile {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.headerProfile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: inherit;
  background-color: var(--text-color-gray);
}

.mainColor {
  color: #4d55e5;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.linkContainer span {
  margin-right: 10px;
}

.dflex {
  display: flex;
}

.logoImage {
  /* width: 25%; */
  /* height: auto; */
  /* object-fit: contain; */
  width: 156px;
  height: 36px;
  vertical-align: middle;
}

.goBackLink {
  padding-left: 30px;
  /* padding-left: 45px; */
}

.nabarLinks {
  color: var(--text-color-black) !important;
  font-size: 16px !important;
  padding: 8px 0;
  text-decoration: none;
}

.textWhite {
  color: var(--white-color) !important;
}

.navActive {
  color: var(--main-color-yellow) !important;
}

.signin_button {
  background-color: var(--button-color);
  border: 1px solid var(--main-color);
  color: var(--text-color-black);
  margin-right: 14px;
}

.btn {
  padding: 10px 30px !important;
  border-radius: 30px;
}

.signup_button {
  background-color: var(--main-color);
  color: var(--white-color);
  border: 1px solid var(--main-color);
}

.gapCustm {
  /* gap: 3rem; */
  margin-left: 30px;
  gap: 32px;
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rightContent ul {
  padding-left: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.rightContent ul li {
  margin-right: 30px;
  cursor: pointer;
}

.rightContent ul li:last-child {
  margin-right: 0px;
}

.iconWithCount {
  position: relative;
  user-select: none;
}

.iconWithCount p {
  position: absolute;
  top: -20%;
  left: 0%;
  font-size: 10px;
  background-color: var(--main-color-red);
  padding: 1px 5px;
  border-radius: 50%;
}

/* @media (max-width: 1200px) {
  .gapCustm {
    gap: 4rem;
  }
} */
/* Notification Overlay */
.notifications {
  height: 300px;
  /* width: 250px; */
  background-color: var(--white-color);
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px -1px 3px 3px #00000026;
}

.notifications ul {
  padding-left: 0px;
  list-style: none;
}

.notifications > ul > li {
  border-bottom: 1px solid var(--text-color-gray);
}

.notifications > ul > li:last-child {
  border-bottom: none;
}

.notifications h6 {
  font-size: 15px;
  padding: 10px 20px;
}

.notifyoverlayDiv {
  top: 9px !important;
  left: -140px !important;
  z-index: 1002;
}

.notificationsRead {
  background-color: transparent;
}

.notificationCard {
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
}

.notificationCard .image {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.notificationCard .image img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: top center;
}

.notificationCard .msg p {
  font-size: 15px;
  color: var(--text-color-black);
}

.notificationCard .msg span {
  font-size: 15px;
  color: var(--text-color-gray);
}

/*End of Notification Overlay */

/* Profile Overlay */
.profileOverlayDiv {
  top: 9px !important;
  left: -90px !important;
  z-index: 1002;
}

.signup_button {
  color: var(--white-color) !important;
}

.yellowBtn {
  background-color: var(--main-color-yellow);
  color: var(--text-color-black);
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 10px;
  font-size: 14px;
  text-decoration: none;
  box-shadow: 1px 0px 5px 1px #ffffffb0;
}

.loginBtn {
  background-color: var(--black-color);
  color: var(--white-color);
  font-weight: 500;
  padding: 10px 26px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 1px 0px 5px 1px #ffffffb0;
}

/* Option List Icon */
.optionListItem {
  display: flex;
  align-items: center;
  cursor: pointer !important;
  padding: 10px 20px;
}

.optionListItem:hover {
  background-color: #f8f9fa;
}

.optionListItem:active {
  background-color: #c5c5c5;
}

.optionListItem span {
  margin-left: 10px;
}

.userDetailsBox {
  padding: 10px 0px;
  width: max-content;
  background-color: var(--white-color);
  box-shadow: 0px -1px 3px 3px #00000026;
  border-radius: 10px;
}

.userDetailsBox ul {
  list-style: none;
  padding-left: 0px !important;
  margin-bottom: 0px;
}

.userDetailsBox ul .optionListItem:last-child {
  margin-bottom: 0px;
}

.userDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
}

.userDetails .editProfile_div {
  text-align: center;
}

.profile {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.profile img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: top center;
  background-color: var(--text-color-gray);
}

.dropdownDivider {
  border-bottom: 1px solid #959da533;
}

@media (max-width: 1200px) {
  .gapCustm {
    gap: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 1090px) {
  .gapCustm {
    gap: 15px;
    margin-left: 10px;
  }
  .nabarLinks {
    font-size: 14px !important;
  }
}
