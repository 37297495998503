.page {
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.certificateCol {
  margin-bottom: 16px;
}

.certificateCol > h3 {
  margin-bottom: 16px;
}

.mBlock20 {
  margin-bottom: 20px;
}

.heroSection {
  height: 85vh;
}

.faqSection {
  margin-bottom: 6%;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 6% !important;
  font-size: 53px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.card {
  padding: 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 15px;
}

.card p {
  font-size: 18px;
  color: #e60000;
}

.termsAndCondition {
  display: flex;
  justify-content: center;
}

.submitBtn {
  background-color: #fecb00;
  color: #000;
  padding-inline: 40px;
  padding-block: 15px;
}

.submitBtnDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* For Queries */

@media screen and (max-width: 1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
    font-size: 40px;
  }

  .heading2 span {
    font-size: 66px;
  }
  .heroSection {
    height: 40vh;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
  }

  .heading2 {
    font-size: 35px;
  }

  .page h3 {
    font-size: 28px;
  }

  .submitBtn {
    padding: 9px 30px;
  }

  .page h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 94%;
  }

  .page h3 {
    font-size: 24px;
  }

  .page h2 {
    font-size: 28px;
  }

  .submitBtn {
    padding: 8px 28px;
    font-size: 16px;
  }
}

@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }

  .page h3 {
    font-size: 20px;
  }

  .page h2 {
    font-size: 24px;
  }
}

/*  */
