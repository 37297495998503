.textAreaBox label {
  margin-bottom: 10px;
}

.textAreaBox textarea {
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 15px 20px;
  font-size: 18px;
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: var(--white-color);
  width: 100%;
  outline: none;
}

.textAreaBox textarea::placeholder {
  color: var(--text-color-gray);
}