.card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.InputSection_div {
  padding: 140px 0;
}

.InputSection_inner_div {
  box-shadow: 0px 0px 50px 15px rgba(0, 0, 0, 0.1);
}

.inp_width {
  width: 100%;
}

.inp_div {
  margin-top: 20px;
}

.editbtn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 10px 40px;
  margin-top: 50px;
  font-size: 14px;
  margin-bottom: 30px;
}

.CoverImage_div {
  position: relative;
}

.profile_outter_div {
  position: absolute;
  left: 50%;
  top: 98%;
  transform: translate(-50%, -50%);
}

.profile_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 70px;
}

.profile_text {
  margin-top: 20px;
}

.cover_img {
  width: 100% !important;
  height: 400px !important;
  border-radius: unset !important;
  margin-bottom: 40px;
  object-fit: cover !important;
  object-position: center !important;
}

.cover_img:nth-child(1) {
  width: 100%;
  border-radius: unset !important;
}

.btn_icon {
  display: none;
}

.edit_text {
  margin-left: 10px;
  font-size: 16px;
}

.editSection_row {
  row-gap: 20px;
}

.certificationSection_row {
  row-gap: 30px;
}

.certificationSection_row h2 {
  margin-top: 40px;
}

.certificationSection_row ul li {
  list-style-type: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 10px;
}

.certificationSection_row ul {
  padding-left: 0 !important;
}

.greyText {
  color: gray;
}

.c_p {
  cursor: pointer;
}

.text_section {
  padding: 20px 30px;
}

.start_div {
  text-align: center;
}

.start_div p {
  margin-top: 10px;
  color: gray;
  font-size: 16px;
}

.star {
  font-size: 18px;
}



/* Container */

.container {
  max-width: 75%;
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }
}

@media screen and (max-width:767px) and (min-width:550px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:992px) and (min-width:767px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:1024px) and (min-width:992px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:1440px) and (min-width:1024px) {
  .container {
    max-width: 85%;
  }

}