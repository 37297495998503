.page {}

.container {}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.consultSection {
  margin-bottom: 6%;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 45px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.form {
  background: var(--white-color);
  box-shadow: 0 0 15px rgb(0 0 0 / 12%);
  padding: 30px;
  border-radius: 25px;
  max-width: 85%;
  margin: 0 auto;
}

.formAndContent {
  margin-top: 6%;
}

.formAndContent .left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.formAndContent .left h4 {
  margin-bottom: 20px;
  color: var(--main-color-red);
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 500;
}

.formAndContent .left p {
  color: var(--text-color-gray);
  font-size: 17.7px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.submitBtn {
  background-color: var(--main-color-yellow);
  color: var(--text-color-black);
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 12px;
  font-weight: 500;
  margin-top: 12px;
}

.mb10 {
  margin-bottom: 10px;
}

.bgImgSection {
  margin-bottom: 6%;
}

.bgImageClass {
  padding: 80px 50px;
}

@media (max-width: 1600px) {
  .container {
    max-width: 70%;
  }

  .bgImageClass {
    padding: 150px 0px 54px 44px;
  }
}

@media (max-width: 1280px) {
  .bgImageClass {
    padding: 178px 0px 54px 44px;
  }
}

@media (max-width: 1440px) {
  .container {
    max-width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
  }

  .heading2 span {
    font-size: 66px;
  }

  .container {
    max-width: 90%;
  }

  .form {
    max-width: 100%;
    margin-top: 25px;
  }

  .formAndContent .left h4 {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .formAndContent .left p {
    font-size: 15px;
  }

  .bgImgSection p {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .consultSection {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 90%;
  }

  .bgImageClass {
    padding: 137px 15px 37px 15px;
  }
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }
}

@media screen and (max-width: 520px) {

  .bgImageClass {
    padding: 163px 15px 47px 15px;
    margin: 0 5px !important;
  }

  .join_btn {
    padding: 8px !important;
    font-size: 14px !important;
  }

}

@media screen and (max-width: 425px) {

  .bgImageClass {
    padding: 100px 5px 17px 5px;
  }

  .bgImageClass h2 {
    font-size: 22px !important;
  }

  .bgImageClass p {
    font-size: 14px !important;
  }


}

@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }

  .formAndContent .left h4 {
    font-size: 20px;
  }

  .bgImageClass {
    padding: 130px 5px 35px 5px;
  }
}