.page {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.available_mechanics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.available_mechanics h1 {
  color: #e60000;
  font-weight: bold;
}

.available_mechanics p {
  font-size: 22px;
  color: #8d8d8d;
}

.fliter_div {
  display: flex;
  align-items: center;
}

.fliter_div h2 {
  font-size: 28px;
  color: #2d2d2d;
  font-weight: 600;
}

.filterIcon {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.mech_filter {
  margin-top: 20px;
}

.mech_filter h5 {
  font-size: 18px;
}

.filterBtn_div {
  margin-top: 20px;
}

.filter_btn {
  font-size: 14px;
}

.red_btn {
  background-color: var(--main-color-red);
}

.white_btn {
  border: 1px solid var(--main-color-red);
  color: var(--main-color-red);
  margin-left: 20px;
}

.mobileMachanics_AferSection {
  margin-top: 50px;
}

.background_with_image_Section {
  margin: 50px 0;
}

.OfferServices_Section .lower_text_heading {
  text-align: center;
  color: #e60000;
  position: relative;
  padding-bottom: 45px;
}

.OfferServices_Section .lower_text_heading>span {
  position: absolute;
  font-size: 130px;
  left: 18.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  /* top: -49%; */
  z-index: -1;
  display: flex;
}

.OfferServices_Section {
  padding: 50px 0;
}

.FrequientlyAsked_Section {
  background: #2d2d2d;
  padding: 50px 0;
}

.FAQ_head .upper_text_heading {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: white;
  font-weight: 400;
}

.FAQ_head .lower_text_heading {
  color: white;
  text-align: center;
  position: relative;
  padding-bottom: 45px;
}

.FAQ_head .lower_text_heading>span {
  left: 36%;
  z-index: 1;
  color: #70707026;
  position: absolute;
  font-size: 130px;
  text-transform: uppercase;
  top: -25%;
  display: flex;
}

.aboutUs_Section_div .upper_text_heading {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: black;
  font-weight: 400;
}

.aboutUs_Section_div .lower_text_heading {
  text-align: center;
  color: black;
  position: relative;
  padding-bottom: 45px;
}

.aboutUs_Section_div .lower_text_heading>span {
  position: absolute;
  font-size: 130px;
  left: 18.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  z-index: -1;
  display: flex;
}

.aboutUs_Section {
  margin-top: 50px;
}

.bgimgtyer {
  padding: 110px 50px;
}

.bgimgnetwork {
  padding: 160px 50px 100px 50px;
}

.join_btn {
  background-color: var(--main-color-yellow);
  color: black;
  font-size: 14px;
}

.Footer_Section {
  margin-top: 100px;
}

.aboutUs_row {
  align-items: center;
  padding: 0px 0 60px;
}

.aboutImg_sec img {
  transform: scale(1.3);
  position: relative;
  left: -10%;
}

.aboutSection_para {
  padding-left: 50px;
  padding-top: 40px;
}

.aboutSection_para>h5 {
  max-width: 80%;
  color: #e60000;
  text-transform: uppercase;
  line-height: 1.5;
  padding-bottom: 6px;
}

.aboutSection_para>p {
  color: #8d8d8d;
  font-size: 17.7px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.learn_more_btn {
  background-color: var(--main-color-yellow);
  color: black;
  font-size: 14px;
}

.loc_input_home {
  width: 70%;
  margin-right: 2%;
  height: auto;
  /* margin-top: -6px; */
}

.searchBar_div {
  display: flex;
  align-items: center;
}

.searchBtn {
  background-color: var(--main-color-red);
  padding: 10px 16px;
  height: 50px;
  width: 100%;
}

.Map_container {
  position: absolute;
  top: 30;
  right: 0;
  left: 0;
  z-index: 1000 !important;
}

.Map_section {
  padding-bottom: 60px;
}

.map_container {
  margin-top: -38%;
}

.custom_Container {
  max-width: 70%;
  margin: auto;
}

.custom_Container2 {
  max-width: 60%;
}

.custom_Container_faq {
  max-width: 55% !important;
}

.machanicCard_row {
  row-gap: 40px;
  margin-top: 50px !important;
}

.OfferServices_Section_row {
  row-gap: 40px;
}

.mobileMachanics_AferSection_row {
  row-gap: 40px;
}

.noData>div {
  width: unset !important;
  height: 240px;
}

@media (max-width: 1720px) {
  .map_container {
    margin-top: -33%;
  }
}

@media (max-width: 1600px) {
  .map_container {
    margin-top: -38%;
  }

  .custom_Container {
    max-width: 75% !important;
  }

  .searchBar_div {
    margin-top: 30px;
  }

  .heroSection {
    padding-top: 148px !important;
    padding-bottom: 32% !important;
  }

  .aboutUs_Section_div .lower_text_heading>span {
    font-size: 110px;
    left: 25.8%;
    top: -30%;
  }

  .OfferServices_Section .lower_text_heading>span {
    font-size: 110px;
    left: 28.8%;
    top: -36%;
  }

  .FAQ_head .lower_text_heading>span {
    left: 36%;
    font-size: 110px;
    top: -34%;
  }
}

@media (max-width: 1440px) {
  .custom_Container {
    max-width: 82% !important;
  }

  .map_container {
    margin-top: -37%;
  }

  .custom_Container_faq {
    max-width: 80% !important;
  }
}

/* @media (max-width: 1367px) {
  .custom_Container_faq {
    max-width: 80% !important;
  }
} */

@media (max-width: 1280px) {
  .custom_Container {
    max-width: 90% !important;
  }

  .map_container {
    margin-top: -32%;
  }

  .aboutUs_Section_div .lower_text_heading>span {
    position: absolute;
    font-size: 80px;
    left: 31.8%;
    top: -25%;
  }

  .OfferServices_Section .lower_text_heading>span {
    position: absolute;
    font-size: 80px;
    left: 31.8%;
    top: -25%;
  }

  .FAQ_head .lower_text_heading>span {
    top: -63%;
  }
}

@media (max-width: 992px) {
  .bgimgtyer {
    /* padding: 0px 17px 92px 17px; */
  }

  .custom_Container_faq {
    max-width: 85% !important;
  }

  .bgimgnetwork {
    padding: 142px 0px 44px 20px;
  }

  .heroSection {
    padding-top: 75px !important;
  }

  .map_container {
    margin-top: -26%;
    height: 400px !important;
  }

  .background_img_container {
    max-width: 100%;
  }

  .aboutUs_Section_div .lower_text_heading>span {
    font-size: 70px;
    left: 28.8%;
  }

  .OfferServices_Section .lower_text_heading>span {
    font-size: 70px;
    left: 28.8%;
  }

  /* .FAQ_head .lower_text_heading>span{
    top: -63%;
} */
}

@media (max-width: 800px) {
  .map_container {
    margin-top: -20%;
  }

  .aboutUs_Section_div .lower_text_heading>span {
    left: 25.8%;
  }

  .OfferServices_Section .lower_text_heading>span {
    left: 25.8%;
  }
}

@media (max-width: 768px) {
  .map_container {
    margin-top: -22%;
  }

  .aboutImg_sec img {
    transform: scale(0.9);
    position: unset;
    left: unset;
  }

  .aboutUs_Section_div .lower_text_heading>span {
    left: 20.8%;
  }

  .OfferServices_Section .lower_text_heading>span {
    left: 20.8%;
  }

  .FAQ_head .lower_text_heading>span {
    top: -41%;
    left: 32%;
  }

  .bgimgtyer {
    padding: 110px 14px;
  }
}

@media (max-width: 578px) {
  .searchBtn {
    margin-top: 10px;
    padding: 8px 0;
    height: unset;
  }
}

@media (max-width: 540px) {
  .aboutUs_Section_div .lower_text_heading>span {
    left: 11.8%;
  }

  .OfferServices_Section .lower_text_heading>span {
    left: 11.8%;
  }

  .map_container {
    height: 300px !important;
  }
}

@media (max-width: 480px) {
  .map_container {
    margin-top: -20%;
  }

  /* .aboutImg_sec img {
    transform: scale(0.9);
    position: unset;
    left: unset;
  }
  .aboutUs_Section_div .lower_text_heading>span {
    
    left: 20.8%;
  }
  
.OfferServices_Section .lower_text_heading>span {
  
  left: 20.8%;
} */
}