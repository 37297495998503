.container {
    height: inherit;
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    color: red;

}