 .UserDetailCard {
   background-color: white;
   border-radius: 20px;
   width: 100%;
   margin: 20px auto;
   margin-bottom: 30px;
   padding: 30px;
   color: gray;
   vertical-align: middle;
   box-shadow: 0px 0px 50px 15px rgba(0, 0, 0, 0.1);
 }

 .profileSubContainer {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 10px;
 }

 .profile {
   height: 100px;
   width: 100px;
   border-radius: 50%;
   background-size: cover;
   background-repeat: no-repeat;
   margin-right: 10px;
 }

 .profile img {
   width: 100%;
   height: 100%;
   border-radius: 50%;
 }

 .bookingStatus_conatiner {
   display: flex;
   flex-direction: column;
   margin-left: 20px;
 }

 .bookingStatus_conatiner h5 {
   color: #000000;
   font-weight: bold;
   text-transform: capitalize;
 }

 .bookingStatus_conatiner h6 {
   text-transform: capitalize;
   color: "#e60000"
 }

 .infoSubContainer {
   display: flex;
   justify-content: flex-start;
   align-items: center;
 }

 .booking_card_inner_div {
   row-gap: 15px;
   margin: 30px 0 !important;
 }

 .UserDetailCard i {
   font-size: 23px;
   color: #e60000;
   margin-right: 14px;
 }

 .plate_img {
   width: auto;
   height: 24px;
 }

 .infoSubContainer img {
   width: 23px;
   margin-right: 14px;
   filter: invert(17%) sepia(83%) saturate(5829%) hue-rotate(359deg) brightness(83%) contrast(122%);
 }

 .infoSubContainer p {
   margin: 0;
   word-break: break-all;
   font-size: 18px;
 }

 .button_container {
   /* display: flex;
    align-items: center; */

 }

 .secondaryBtn {
   background-color: #ffcc00;
   padding: 10px 16px;
   text-decoration: none;
   cursor: pointer;
   border: none;
   border-radius: 10px;
   color: #000000;
   font-weight: 500;
   /* margin-left: 20px; */
 }

 .completedBtn {
   background-color: #4ccc32;
   cursor: pointer;
   border: none;
   padding: 10px 30px;
   /* margin-top: 25px; */
   border-radius: 10px;
   color: white;
   font-weight: 500;
   margin-left: 20px;
 }

 .rejectBtn {
   background-color: #e60000;
   cursor: pointer;
   border: none;
   padding: 10px 30px;
   /* margin-top: 25px; */
   border-radius: 10px;
   color: white;
   font-weight: 500;
   margin-left: 20px;
 }

 .btn_loading {
   text-align: center;
   margin-bottom: 30px;
 }

 .not_found_div {
   height: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
 }

 .not_found_p {
   font-size: 28px !important;
   padding-right: 56px !important;
   padding-top: 0px !important;
 }

 .not_found_img {
   width: 100px;
   height: auto;
 }

 .reviewBtn {
   background-color: var(--main-color-yellow);
   color: var(--text-color-black);
   padding: 10px 30px;
   font-size: 15px;
 }