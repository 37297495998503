.page {}

.container {}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.card {
  padding: 20px;
  box-shadow: rgb(38 39 39 / 12%) 0px 0px 0px 0px, rgb(109 110 111 / 32%) 0px 2px 4px 0px;
  border-radius: 10px;
}

.card img {
  height: 100px;
  border-radius: 50px;
}

.card p {
  font-size: 18px;
}

.middleSection {
  padding-top: 140px;
  padding-bottom: 90px;
  min-height: calc(100vh - 460px);
}

.mt10 {
  margin-top: 10px;
}

.dFlex {
  display: flex;
}

.dFlex p {
  color: var(--text-color-gray);
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.dFlex svg {
  color: var(--main-color-red);
}

.mTop20 {
  margin-top: 20px;
}

.ratingNreview {
  margin-top: 20px;
}

.ratingNreview h1 {
  font-size: 32px;
  margin-bottom: 0px;
}

.imgAndUserName {
  display: flex;
  align-items: center;
}

.imgAndUserName .img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
  object-position: top center;

}

.nameAndVerify {
  display: flex;
  align-items: center;
}

.nameAndVerify h3 {
  margin-left: 20px;
  text-transform: capitalize;
}

.nameAndVerify img {
  width: 40px;
  height: 100px;
  margin-left: 20px;
  object-position: center center;
  object-fit: contain;
}

.icon {
  font-size: 25px;
  display: inline-flex;
}

.mb10 {
  margin-bottom: 10px;
}

.mt20 {
  margin-top: 20px;
}

.noReviewsText {
  color: var(--text-color-gray);
  font-size: 14px;
  font-weight: 600;
}

.submitBtn {
  background-color: var(--main-color-yellow);
  padding: 10px 36px;
  border-radius: 10px;
  color: var(--text-color-black);
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
}


.skeleton {
  width: 100%;
  height: 450px;
}


/* For Container */
.container {
  max-width: 75%;
}

@media screen and (max-width:1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }

  .skeleton {
    height: 500px;
  }
}

@media screen and (max-width:992px) {
  .container {
    max-width: 90%;
  }

  .skeleton {
    height: 500px;
  }
}

@media screen and (max-width:767px) {
  .container {
    max-width: 90%;
  }

  .skeleton {
    height: 500px;
  }
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .card h3 {
    font-size: 25px;
  }

  .skeleton {
    height: 600px;
  }
}