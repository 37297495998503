.card {
  background: var(--white-color);
  box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  padding: 30px 25px;
  border-radius: 30px;
}

.card img {
  width: 100px;
  height: 115px;
}

.card h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 50px;
  margin-top: 25px;
}
