.card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 20px;
  margin-bottom: 30px;
  min-height: 250px;
}

.row {
  min-height: inherit;
}

.dFlex {
  display: flex;
}

.title {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: 18px;
}

.infoDetail {
  color: red;
  margin-left: 10px;
  font-size: 16px;
}


.textBlack {
  color: var(--text-color-black);
}

.pTB20 {
  padding: 20px 0px;
}

.voucherImg {
  width: 100%;
  border-radius: 20px 0 0px 20px;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
}


@media screen and (max-width:1220px) and (min-width:992px) {
  .infoDetail {
    font-size: 15px;
  }

  .title {
    font-size: 16px;
  }
}

@media screen and (max-width:992px) and (min-width:768px) {
  .infoDetail {
    font-size: 14px;
  }

  .title {
    font-size: 15px;
  }

}

@media screen and (max-width:767px) {
  .pLR20 {
    padding: 0px 20px;
  }

  .voucherImg {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 180px;
    object-fit: cover;
  }

  .infoDetail {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .title {
    font-size: 15px;
    margin-bottom: 4px;
  }



}