.page {}


.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.heroSection {
  height: 85vh;
}

.faqSection {
  margin-bottom: 6%;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 6% !important;
  font-size: 53px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.card {
  padding: 20px;
  box-shadow: rgb(164 169 171 / 12%) 0px 2px 4px 0px, rgb(224 231 234 / 32%) 0px 2px 16px 0px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dGrid {
  display: grid;
}

.card h2 {
  color: var(--text-color-black);
  text-align: center;
  font-weight: 700;
}

.email {
  background-color: #e60000;
  width: 100px;
  padding: 20px;
  border-radius: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email svg {
  font-size: 45px;
  color: var(--white-color);
}

.mBottom20 {
  margin-bottom: 20px;
}

.submitBtn {
  background-color: #fecb00;
  color: #000;
  padding: 10px 40px;
  font-size: 16px;
}

.submitBtnDiv {
  display: flex;
  margin-top: 10px;
}

.supportHead h2 {
  font-size: 32px;
  margin-bottom: 40px;
  margin-top: 140px;
}

.mb40 {}


@media screen and (max-width: 991px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
    font-size: 40px;
  }

  .heading2 span {
    font-size: 66px;
  }

  .mb40 {
    margin-bottom: 40px;
  }
}


@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }

}


/* For Container */

.container {
  max-width: 75%;
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .heading2 {
    font-size: 35px;
  }

  .supportHead h2 {
    font-size: 30px;
    margin-bottom: 30px;
    margin-top: 100px;
  }

  .card h2 {
    font-size: 25px;
  }


}

@media screen and (max-width:767px) and (min-width:550px) {
  .container {
    max-width: 90%;
  }

  .heading2 {
    font-size: 35px;
  }

}

@media screen and (max-width:992px) and (min-width:767px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width:1024px) and (min-width:992px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width:1440px) and (min-width:1024px) {
  .container {
    max-width: 85%;
  }
}