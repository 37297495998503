.ForgetPassword_div{
    padding: 20px;
    text-align: center;
}
.ForgetPassword_div_inp{
    margin: 20px 0 0 0;
}
.ForgetPassword_btn{
    color: black;
    background-color: var(--main-color-yellow);
    padding: 15px 60px;
    /* border-radius: 0; */
  margin: 20px 0;
}
.c_p{
    cursor: pointer;
}