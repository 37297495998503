.card {
    background-color: var(--white-color);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    padding: 0;
    vertical-align: middle;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.header {
    position: relative;
}

.ratingAbsolute {
    position: absolute;
    top: -10px;
    left: 40px;
}

.imgDiv {
    position: relative;
}

.rating {
    position: absolute;
    top: 0px;
    left: 40%;
    transform: translateX(-50%);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.rating span {
    color: var(--text-color-black);
    font-size: 14px;
}

.card .image {
    width: 100%;
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;


}

.card .desc {
    padding: 15px 20px;

}

.location {
    display: flex;
}

.location .icon {
    width: 25px;
    height: auto;
    color: var(--main-color-red);
    flex-shrink: 0;
    align-self: flex-start;
    vertical-align: middle;
}

.location span {
    margin-left: 10px;
    color: var(--text-color-gray);
    font-size: 16px;
    height: 42px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.btnDiv {
    display: flex;
    justify-content: center;
}

.btn {
    background-color: var(--main-color-yellow);
    color: var(--text-color-black);
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    margin-bottom: 25px;
    border-radius: 10px;
    font-weight: 500;
    margin-top: 15px;
    font-size: 16px;
}

.verifiedIcon {
    position: absolute;
    top: 0px;
    width: 50px;
    right: 0px;
}