.footer {
  width: 100%;
  background: #efefef;
}

.logo {
  width: 200px;
}

.logo img {
  width: 100%;
  height: auto;
}

.innerBox {
  padding: 24px 0px 0px 0px;
}

.borderBottom {
  border-bottom: 1px solid #efefef;
}

.socialMedia {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.socialIcons {
  margin: 0px;
  margin-left: 16px;
  display: flex;
  padding-left: 0px;
  list-style: none;
  align-items: center;
}

.socialIcons li {
  margin-right: 24px;
}

.socialIcons li .socialIcon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.break {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin: auto 16px;
  display: inline-block;
  background-color: #717171;
}

.lastLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.lastLine>div {
  display: inline-flex;
}

.lastLine p {
  font-family: "Poppins";
  font-size: 13px;
  margin: 0px;
}

.lastLine>div span {
  font-family: "Poppins";
  font-size: 13px;
}

.pageLink {
  margin-bottom: 12px;
  font-size: 14px;
  color: #2d2d2d;
  line-height: 1.8;
  font-family: "Poppins";
  text-decoration: none !important;
}

.itemsCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.platformIcons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.platformIcon img {
  width: 150px;
}

.platformIcons>a:last-child {
  margin-left: 30px;
}

.pagesArray {
  padding-left: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.pagesArray li {
  flex-basis: 33.3%;
}

@media screen and (max-width: 500px) {
  .pagesArray li {
    flex-basis: 50%;
  }

  .platformIcon img {
    width: 120px;
  }

  .logo {
    width: 150px;
  }
}

@media screen and (max-width: 370px) {
  .platformIcon img {
    width: 110px;
  }

  .platformIcons>a:last-child {
    margin-left: 10px;
  }

  .lastLine p {
    font-size: 12px;
  }

  .lastLine>div span {
    font-size: 12px;
  }

  .break {
    margin: auto 8px;
  }
}