.card {
    display: flex;
}

.card h5 {
    font-size: 20px;
    margin-bottom: 0px;
}

.details {
    margin-left: 20px;
}

.card p {
    font-size: 16px;
    color: var(--text-color-gray);
}