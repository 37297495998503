.login_div{
    padding: 20px;
    text-align: center;
}
.login_div_checkBox{
    text-align: left;
    margin: 20px 0;
}
.login_div_inp{
    margin: 20px 0 0 0;
}
.login_btn{
    color: black;
    background-color: var(--main-color-yellow);
    padding: 15px 60px;
    /* border-radius: 0; */
  margin: 20px 0;
}
.textGray{
    color: gray;
}
.login_para{
    font-size: 18px;
    margin-bottom: 30px;
}
.textred{
    color: var(--main-color-red);
}
.Sign_Up{
    font-size: 18px;
    margin-left:5px;
}
.Sign_Up_text{
    font-size: 18px;
}
.c_p{
    cursor: pointer;
}
.forget_password{
    text-decoration: underline;
}