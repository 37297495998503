.header_sect {
  clip-path: polygon(100% 0%, 0% 0%, 0% 71%, 100% 100%);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 30%;
  position: relative;
}

.header_sect:before {
  content: "";
  background: #0000006b;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.header_sect .sect1_title {
  text-align: center;
  color: var(--white-color);
  padding-top: 24px;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 700;
}

.container {
  max-width: 70%;
  text-align: center;
}

.header_sect .sect1_para {
  color: var(--white-color);
  padding-top: 10px;
}

@media screen and (max-width: 991px) {
  .header_sect {
    padding-top: 150px;
  }

  .header_sect .sect1_title {
    font-size: 30px;
  }

  .header_sect .sect1_para {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .header_sect .sect1_title {
    font-size: 25px;
  }

  .header_sect .sect1_para {
    font-size: 15px;
  }
  .header_sect {
    clip-path: polygon(100% 0%, 0% 0%, 0% 80%, 100% 100%);
  }
}

@media screen and (max-width: 375px) {
  .header_sect {
    padding-top: 150px;
  }

  .header_sect .sect1_title {
    font-size: 20px;
  }

  .header_sect .sect1_para {
    font-size: 14px;
  }
}

/* For Container */
.container {
  max-width: 50%;
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 90%;
  }
}
