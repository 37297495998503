.custom_Container {
  max-width: 70%;
  margin: auto;
}

/* .container{
  max-width: 70%;
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.lower_text_heading {
  color: var(--main-color-red);
  position: relative;
  padding-bottom: 45px;
  width: max-content;
  margin: 0px auto;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
  display: none;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 45px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.lower_text_heading>span {
  position: absolute;
  font-size: 120px;
  left: 50%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  z-index: -1;
  display: flex;
  width: max-content;
  transform: translate(-50%, -50%);
}

.aboutUs_Section {
  margin-top: 50px;
}

.email_card_container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.email_card_div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.email_card_text {
  /* margin-top: 10px; */
  margin-bottom: 20px;
}

.email_card_img_div {
  width: 90px;
  height: 90px;
  background-color: var(--main-color-red);
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -67%);
}

.email_card_icon {
  font-size: 50px;
  color: white;
}

.contactus_Section {
  background: linear-gradient(188deg, #e60000 78%, #efefef 0);
  padding: 203px 0 290px 0;
  -webkit-clip-path: polygon(100% 30%, 0 0, 0 100%, 100% 100%);
  clip-path: polygon(100% 30%, 0 0, 0 100%, 100% 100%);
}

.contactus_Section_div h1,
.contactus_Section_div p {
  color: white;
  text-align: center;
}

.form_div_row {
  margin-top: 50px;
  row-gap: 15px;
}

.btn_div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.submit_btn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 16px 60px;
}

.custom_Container_form {
  max-width: 50% !important;
}

.Footer_Section {
  /* margin-top: 100px; */
}

.aboutUs_row {
  align-items: center;
  padding: 0px 0 60px;
}

@media (max-width: 1600px) {
  .container {
    max-width: 70%;
  }

  .lower_text_heading>span {
    font-size: 90px !important;
    top: -15%;
  }
}

@media (max-width: 1440px) {
  .custom_Container {
    max-width: 80% !important;
  }

  .container {
    max-width: 80% !important;
  }

  .custom_Container_form {
    max-width: 80% !important;
  }
}

@media (max-width: 1280px) {
  .custom_Container {
    max-width: 90% !important;
  }

  .container {
    max-width: 90% !important;
  }

  .custom_Container_form {
    max-width: 90% !important;
  }
}

@media (max-width: 1024px) {
  .lower_text_heading {
    font-size: 40px !important;
  }

  .lower_text_heading>span {
    font-size: 76px !important;
    top: -15%;
  }
}

@media screen and (max-width: 991px) {
  .heading1 {
    font-size: 18px;
    display: none;
  }

  .heading2 {
    margin-bottom: 45px;
  }

  .heading2 span {
    font-size: 66px;
  }

  .container {
    max-width: 90%;
  }

  .form {
    max-width: 100%;
    margin-top: 25px;
  }

  .formAndContent .left h4 {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .formAndContent .left p {
    font-size: 15px;
  }

  .lower_text_heading>span {
    font-size: 65px !important;
  }
}


@media screen and (max-width: 768px) {
  .container {
    max-width: 94%;
  }

  .lower_text_heading {
    font-size: 30px !important;
  }


  .lower_text_heading>span {
    font-size: 57px !important;
  }

  .contactSection {
    padding-top: 100px !important;
  }

  .contactus_Section {
    padding: 98px 0 290px 0;
    clip-path: polygon(100% 9%, 0 0, 0 100%, 100% 100%);
  }
}

@media screen and (max-width: 520px) {
  .lower_text_heading {
    padding-bottom: 20px;
  }

  .lower_text_heading>span {
    font-size: 45px !important;
  }

  .submit_btn {
    padding: 10px 50px !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 480px) {



  .contactSection {
    padding-top: 70px !important;
  }
}

@media screen and (max-width: 375px) {


  .formAndContent .left h4 {
    font-size: 20px;
  }

  .lower_text_heading>span {
    font-size: 40px !important;
  }

  .lower_text_heading {
    font-size: 26px !important;
  }



}