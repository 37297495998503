.mapContainer {
}
.popupDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imageNameDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.popImage {
  width: 50px;
  height: 50px;
}
.popUser {
  margin: 0 !important;
  font-size: 24px;
  margin-left: 12px !important;
  color: #ffcc00;
  font-weight: bold;
}

.popAddress {
  margin: 0;
  margin-bottom: 0 !important;
  font-size: 14px;
  color: whitesmoke;
  text-align: center;
}

@media (max-width: 992px) {
  .mapContainer {
    height: 400px;
  }
}
