.card {
    background-color: var(--white-color);
    border-radius: 18px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 10px;

}

.cardHeader {
    width: 100%;
    position: relative;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.cardImg {
    width: 100%;
    height: 300px;
    border-radius: inherit;

}

.cardImg img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 100%;
    border-radius: inherit;

}

.card h2 {
    font-size: 28px;
    color: var(--text-color-black);
    font-weight: 600;
}

.cardBody {
    padding: 20px;
}

.verifiedMech {
    width: 45px;
    height: 45px;
}

.icon {
    color: var(--main-color-red);
    font-size: 25px;


}

.iconWithText {
    display: flex;
    margin-bottom: 10px;

}

.iconWithText p {
    font-size: 14px;
    color: var(--text-color-gray);
    margin-left: 5px;
}

.aCenter {
    display: flex;
}

.location {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
}

.yellowBg {
    position: absolute;
    top: -10px;
    left: 40px;
}

.rating div {
    display: inline-flex !important;
    vertical-align: middle;
}

.rateNum {
    font-size: 14px;
    font-weight: 600;
}

.yellowBg .rating {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.yellowBg .rating span {
    color: var(--text-color-black);
    display: inline-flex;
}