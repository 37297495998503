.background_with_image_Section {
  margin: 50px 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.JoinNetwork_Section_div .upper_text_heading {
  text-align: center;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 1px;
  color: black;
  font-weight: 400;
}

.JoinNetwork_Section_div .lower_text_heading {
  text-align: center;
  color: var(--main-color-red);
  position: relative;
  padding-bottom: 45px;
}

.JoinNetwork_Section_div .lower_text_heading>span {
  position: absolute;
  font-size: 130px;
  left: 24.8%;
  text-transform: uppercase;
  color: #70707012;
  top: -25%;
  z-index: -1;
  display: flex;
}

.JoinNetwork_Section {
  margin-top: 50px;
}

.Footer_Section {
  margin-top: 100px;
}

.JoinNetwork_Section_row {
  row-gap: 50px;
  margin-top: 20px;
}

.subsBtn {
  padding: 12px 20px;
  background-color: #e60000;
}

.subsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;
}

.subsDiv h2 {
  margin-bottom: 0px;
}

.noDataContainer>div {
  width: unset;
}

@media (max-width: 991px) {
  .subsDiv {
    margin-top: 60px;
  }

  .subsDiv h2 {
    font-size: 32px !important;
  }

}

@media (max-width: 768px) {
  .subsDiv h2 {
    font-size: 29px !important;
  }


  .subsBtn {
    padding: 10px 16px;
    background-color: #e60000;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .subsDiv {
    flex-direction: column;
  }

  .subsDiv h2 {
    margin-bottom: 12px;
  }

  .subsBtn {
    padding: 10px 16px;
    background-color: #e60000;
    font-size: 18px;
  }

  .subsBtn {
    font-size: 15px;
  }
}



/* Container */

.container {
  max-width: 75%;
}

@media screen and (max-width:1440px) {
  .container {
    max-width: 85%;
  }

}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }

  .subsDiv h2 {
    font-size: 24px !important;
  }

}