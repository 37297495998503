.mainContainer {
  max-width: 94%;
  padding: 0;
  margin: 100px auto 33px auto;
  min-height: calc(100vh - 469px)
}

.chatHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 30px 0;
}

.chatHeader p {
  color: var(--gray-color);
}

.chatSiderName .chatDescript {
  color: var(--lightGray-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.chatSiderName .chatName {
  font-size: 16px;
  text-transform: capitalize;
}

.chatSiderTime .time {
  color: var(--lightGray-color);
}

.chatSiderImg {
  width: 45px;
  height: 45px;
}

.chatSiderImg img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  display: inline-flex;
  flex-shrink: 0;
}

.chatSidebarList {
  display: flex;
  gap: 15px;
  align-items: center;
}

.chatSidebarMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

/* Main Chat screen */
.chatHeaders {
  display: flex;
  align-items: center;
  gap: 18px;
  border-bottom: 1px solid #e8e8e8;
  margin: 10px 0 16px 0;
  padding: 0 0 16px 0;
}

.chatHeader {
  width: 60px;
  height: 60px;
}

.chatHeader img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  width: 60px;
  height: 60px;
}

.chatHeaderCall_icons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
}

.phone,
.video {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 40px;
  height: 40px;
  border-radius: 30%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.chatBoxTime p {
  text-align: center;
  color: var(--blackish-color);
  margin: 15px 0;
}

.MessageImg {
  width: 45px;
  height: 45px;
}

.MessageImg img {
  border-radius: 30%;
  object-fit: cover;
  object-position: center;
  display: inline-flex;
  flex-shrink: 0;
}

/* .MessageImg img:nth-child(1){
    display: none;
}

.MessageImg img:nth-child(2){
    display: block;
} */
.incomingMessage {
  /* display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
  width: 70%; */

  background-color: #7674742e;
  padding: 20px;
  margin: 20px 0;
  color: var(--text-color-black);
  width: 70%;
  border-radius: 20px;
  margin-right: auto;
}

.time {
  color: #707070d1;
}

.Incomingtime {
  color: #707070;
  float: right;
}

.outcomingMessage {
  background-color: var(--main-color-red);
  padding: 20px;
  margin: 20px 0;
  color: #fff;
  width: 70%;
  border-radius: 20px;
  margin-left: auto;
  text-align: end;
  position: relative;
}

.textMessage {
  background-color: var(--gray2-color);
  padding: 15px 10px;
  border-radius: 20px 20px 20px 0;
}

.textMessage p {
  color: var(--maroon-color);
}

/* .outcomingMessage {
  background-color: var(--main-color-red);
  padding: 15px 10px;
  border-radius: 20px 20px 20px 0;
  width: 70%;
  margin-left: auto;
  margin-bottom: 10px;
}
.outcomingMessage p {
  color: #fff;
} */
.sendMessage_field {
  margin: 15px 0;
}

.sendMessageMain {
  position: relative;
}

.sendMessage_field input {
  padding: 20px 75px;
  width: 100%;
  background: #dfdfdf;
  border-radius: 30px;
  color: var(--blackish-color);
}

.plusIcon {
  width: 38px;
  height: 38px;
  background-color: var(--main-color-red);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 21.3%;
  left: 1%;
}

.sendIcons {
  display: flex;
  gap: 20px;
  position: absolute;
  left: 90%;
  top: 33%;
}

/*  */
.ChatRoom_innerDiv {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 10px;
  cursor: pointer;
  border-bottom: 1px solid #8080806b;
}

.ChatRoom_img_div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.ChatRoom_img_div img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.ChatRoom_text_div {
  margin-left: 12px;
  width: 100%;
}

.ChatRoom_innertext_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChatRoom_innertext_div h5 {
  font-size: 17px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ChatRoom_innertext_div p {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--main-color-red);
}

.ChatRoom_innertext_div_para {
  font-size: 17px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--lightGray-color);
}

.inbox {
  /* background: var(--white-color);
  box-shadow: 0px 0px 11px #2222221c; */
  border-radius: 20px;
  height: 100%;
  position: relative;
}

.chattingDiv {
  padding: 16px 16px 80px 16px;
  height: calc(100vh - 284px);
  overflow-y: auto;

  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  /* overflow: scroll; */
}

.sendBtnDiv {
  position: absolute;
  width: calc(100% - 1px);
  bottom: -1px;
  padding: 0px 15px 16px 16px;
  background-color: var(--white-color);
  border-radius: 20px;
}

.mainContainer p {
  margin: 0;
}

.SendInputMainContainer {
  background-color: #e1e1e1;
}

.sendInput {
  font-size: 16px !important;
  color: #000 !important;
}

.SendInputBtnContainer {
  background-color: transparent !important;
}

.SendInputBtnContainer:hover,
.SendInputBtnContainer:active+.SendInputBtnContainer,
.SendInputBtnContainer:checked+.SendInputBtnContainer,
.SendInputBtnContainer.active,
.SendInputBtnContainer:active,
.show>.SendInputBtnContainer.dropdown-toggle {
  background-color: transparent !important;
}

.SendInputBtnContainer:active+.SendInputBtnContainer:focus,
.SendInputBtnContainer:checked+.SendInputBtnContainer:focus,
.SendInputBtnContainer:focus,
.SendInputBtnContainer.active:focus,
.SendInputBtnContainer:active:focus,
.show>.SendInputBtnContainer.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.productTitle {
  color: var(--blackish-color);
  font-size: 20px;
}

.loadMoreButtonDiv {
  display: flex;
  justify-content: center;
}

.loadMoreButtonDiv button {
  background-color: var(--main-color-red);
  color: #fff;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 5px;
}

.noData {
  height: 100%;
}

.noData div {
  width: unset !important;
  height: 240px;
}


/*  */

.mBottom70 {
  margin-bottom: 70px;
}

@media (max-width: 768px) {
  .mainContainer {
    margin: 80px auto 40px auto;
  }

  .incomingMessage {
    width: 90%;
  }

  .outcomingMessage {
    width: 90%;
  }
}

@media (max-width: 540px) {
  .sendIcons {
    left: 84%;
  }
}

@media (max-width: 480px) {
  .sendIcons {
    left: 80%;
  }

  .sendMessage_field input {
    padding: 12px 75px;
  }

  .plusIcon {
    top: 10.3%;
  }
}

@media (max-width: 375px) {
  .plusIcon {
    top: 18.3%;
    left: 2%;
    width: 30px;
    height: 30px;
  }

  .sendMessage_field input {
    padding: 10px 45px;
  }

  .sendIcons {
    left: 74%;
    top: 29%;
  }

  .incomingMessage,
  .outcomingMessage {
    width: 90%;
  }

  .chatHeader {
    width: 50px;
    height: 50px;
  }

  .chatHeaders {
    gap: 2px;
  }

  .chatHeaderCall_icons {
    gap: 8px;
  }
}