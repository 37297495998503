.card {
  box-shadow: 0 0 15px #0000001f;
  border-radius: 20px;
  background-color: var(--white-color);
  height: 550px;
}

.header {
  background-color: var(--main-color-red);
  border-radius: 20px;
  padding: 25px 20px;
  height: 140px;
}

.header h4,
.header h5 {
  color: var(--white-color);
  text-align: center;
  margin-bottom: 0px;
}

.header h4 {
  font-size: 26px;
}

.header h5 {
  font-size: 19px;
  margin-top: 25px;
}

.content {
  padding: 20px 20px;
}

.content .list {
  list-style: none;
  padding-left: 0px;
  height: 300px;
  overflow-y: auto;
}

.list::-webkit-scrollbar {
  width: 5px;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--text-color-gray);
}

.content .list li {
  color: var(--text-color-gray);
  font-size: 17px;
  line-height: 1.5;
  padding-bottom: 20px;
}

.content .list li:last-child {
  padding-bottom: 0;
}

.btn {
  background: var(--main-color-yellow);
  padding: 13px 23px;
  color: var(--text-color-black);
  font-size: 16px !important;
  border-radius: 10px;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}


@media screen and (max-width:1440px) {
  .header h4 {
    font-size: 24px;
  }

  .header h5 {
    font-size: 17px;
    margin-top: 20px;
  }

  .btn {
    padding: 10px 23px;
  }

  .header {
    padding: 25px 15px;
    height: 140px;
  }

}

@media screen and (max-width:1220px) {
  .header h4 {
    font-size: 22px;
  }

  .header h5 {
    font-size: 16px;
  }

}