.card {
    border-radius: 20px;
    box-shadow: 0px 1px 3px 0px #0000003b;
    padding: 20px 15px;
}

.description {
    font-size: 16px;
    color: var(--text-color-gray);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.btn {
    background-color: var(--main-color-red);
    font-size: 15px;
    padding: 8px 20px;
}