.page {}

.container {}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

/* .heroSection {
    height: 85vh;
} */

.faqSection {
    margin-bottom: 6%;
}

.heading1 {

    text-align: center;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 1px;
    color: var(--text-color-dark-gray);
    font-weight: 400;
}

.heading2 {
    text-align: center;
    color: #e60000;
    position: relative;
    margin-bottom: 6% !important;
    font-size: 53px;
}

.heading2 span {
    position: absolute;
    font-size: 130px;
    left: 51%;
    text-transform: uppercase;
    color: hsla(0, 0%, 43.9%, .07058823529411765);
    top: 0%;
    z-index: -1;
    transform: translate(-50%, -50%);
}


.container {
    max-width: 75%;
}

@media screen and (max-width:1440px) {
    .container {
        max-width: 85%;
    }
}

@media screen and (max-width:1024px) {
    .container {
        max-width: 90%;
    }
}

@media screen and (max-width:992px) {
    .heading1 {
        font-size: 18px;
    }

    .heading2 {
        margin-bottom: 45px;
        font-size: 40px;

    }

    .heading2 span {
        font-size: 66px;
    }


}

@media screen and (max-width:768px) {

    .heading2 {
        font-size: 35px;

    }

    .faqSection {
        padding-top: 100px;
    }
}

@media screen and (max-width:550px) {
    .container {
        max-width: 94%;
    }

}

@media screen and (max-width:420px) {
    .faqSection {
        padding-top: 70px;
    }
}

@media screen and (max-width:375px) {
    .heading1 {
        font-size: 16px;
    }

    .heading2 {
        margin-bottom: 40px;
        font-size: 30px;
    }

    .heading2 span {
        font-size: 40px;
    }


}


/*  */