.combobox-input {
  width: 100%;
  border: 1px solid #dddddd;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px 12px 16px;
  height: 100%;
  border-radius: 8px;
}

.combobox-input:focus-visible {
  outline: none;
}

.combobox {
  position: relative;
}

.overlayBox {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: var(--white-color);
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 1px 4px 5px 0px rgb(0 0 0 / 10%);
}