.Container {
    display: flex;
    flex-direction: column;
}

.label {
    /* font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: black; */
}

.disabled {
    color: red;
}

.reactSelect {}

/* .reactSelect:disabled {
    background-color: #f6f6f6;
    color: #b5b5b5;
    border: 1px solid #e5e5e5;
  } */
/* .dropdown.hide.css-b62m3t-container {
    opacity: 0;
    width: 100%;
    visibility: hidden;
    position: absolute;
    height: 0;
    transition: ease 0.5s;
    transform: scale(0);
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    height: 30px;
    padding: 25px 10px;
    width: 100%;
  } */

/* .dropdown.show.css-b62m3t-container {
    opacity: 1;
    transform: scale(1);
    visibility: unset;
    position: unset;
    height: auto;
    transition: ease 0.5s;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    height: 30px;
    padding: 25px 10px;
    width: 100%;
  } */
/* .dropdownContainer {
  }
  .dropdown::placeholder {
    color: #717171;
  } */

/* On Hover */
.dropdown:hover {
    border: 1px solid black !important;
}

/* on Focus */
.dropdown:focus {
    border: 1px solid black;
}

/* On Active */
.dropdown:active {
    border: 1px solid black;
}

/* On Disabled */
.dropdown:disabled {
    background-color: #f6f6f6;
    color: #b5b5b5;
    border: 1px solid #e5e5e5;
}

.noBorder {
    border: 0px solid #e5e5e5;
}

.arrow {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

/*  React Select */
/* .css-1s2u09g-control {
    border-radius: 0px;
    min-height: 30px;
  } */
.leftIconBox {
    position: absolute;
    left: 20px;
    top: 55%;
    bottom: 45%;
    transform: translateY(-50%);
    height: max-content;
}

.dropdownContainer {
    position: relative;
}