.btn {
  font-size: 18px;
  color: var(--white-color);
  outline: none;
  border: none;
  background-color: var(--main-color);
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 400;
  cursor: pointer;
}