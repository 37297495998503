.background_with_image_Section {
  margin: 50px 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.heading1 {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 1px;
  color: var(--text-color-dark-gray);
  font-weight: 400;
}

.heading2 {
  text-align: center;
  color: #e60000;
  position: relative;
  margin-bottom: 45px;
}

.heading2 span {
  position: absolute;
  font-size: 130px;
  left: 51%;
  text-transform: uppercase;
  color: hsla(0, 0%, 43.9%, 0.07058823529411765);
  top: 0%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.JoinNetwork_Section_div .upper_text_heading {
  text-align: center;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 1px;
  color: black;
  font-weight: 400;
}

.JoinNetwork_Section_div .lower_text_heading {
  text-align: center;
  color: var(--main-color-red);
  position: relative;
}

.JoinNetwork_Section_div .lower_text_heading>span {
  position: absolute;
  font-size: 130px;
  left: 50%;
  text-transform: uppercase;
  color: #70707012;
  transform: translateX(-50%);
  z-index: -1;
  bottom: 0%;
}


.Footer_Section {
  margin-top: 100px;
}

.JoinNetwork_Section_row {
  row-gap: 50px;
  margin-top: 50px;
}

.noData>div {
  width: unset !important;
  height: 240px;
}

@media (max-width: 1600px) {
  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 103px;
  }
}

@media (max-width: 1440px) {
  .container {
    max-width: 85%;
  }

  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 85px;
  }
}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .heading1 {
    font-size: 18px;
  }

  .heading2 {
    margin-bottom: 45px;
  }

  .heading2 span {
    font-size: 75px;
  }

  .form {
    max-width: 100%;
    margin-top: 25px;
  }

  .formAndContent .left h4 {
    margin-bottom: 10px;
    font-size: 25px;
  }

  .formAndContent .left p {
    font-size: 15px;
  }

  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 80px;
  }

  .joinSection {
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .JoinNetwork_Section_div .lower_text_heading {
    font-size: 45px;
  }

  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 70px;
  }
}

@media screen and (max-width: 550px) {
  .JoinNetwork_Section_div .upper_text_heading {
    font-size: 30px;
  }

  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 51px;
  }

  .joinSection {
    padding-top: 70px !important;
    padding-bottom: 40% !important;
  }

  .joinSection h1 {
    font-size: 24px !important;
  }

  .container {
    max-width: 94%;
  }

}

@media screen and (max-width: 420px) {
  .JoinNetwork_Section_div .upper_text_heading {
    font-size: 28px;
  }

  .JoinNetwork_Section_div .lower_text_heading {
    font-size: 35px;
  }

  .JoinNetwork_Section_div .lower_text_heading>span {
    font-size: 43px;
  }


  .joinSection h1 {
    font-size: 20px !important;
  }

  .joinSection p {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 375px) {
  .heading1 {
    font-size: 16px;
  }

  .heading2 {
    margin-bottom: 40px;
    font-size: 30px;
  }

  .heading2 span {
    font-size: 40px;
  }

  .formAndContent .left h4 {
    font-size: 20px;
  }



  .joinSection h1 {
    font-size: 18px !important;
  }

  .joinSection {
    padding-top: 50px !important;
    padding-bottom: 66% !important;
  }
}