.cardMainDiv {
  justify-content: space-between;
  align-items: end;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 24px 16px;
  margin: 16px;
  border-radius: 16px;
  display: flex;
  box-shadow: 0 0 4px 0 #00000047;
}
.cardImg {
  margin-bottom: 8px;
}
.cardImg img {
  width: 50px;
  height: auto;
  padding-right: 16px;
  max-width: 100%;
}
.cardNumberDiv {
  display: flex;
  align-items: center;
}
.cardExpiryDiv {
  display: flex;
  align-items: center;
}
.cardLabel {
  margin: 0;
  font-size: 15px;
  padding: 0 6px;
}
.delCardIconDiv {
  position: absolute;
  right: 22px;
  cursor: pointer;
  top: 12px;
}
@media (max-width: 420px) {
  .cardMainDiv {
    padding: 9px;
    margin: 16px 4px;
  }
  .cardLabel {
    font-size: 13px;
    padding: 0 4px;
  }
}
