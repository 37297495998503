html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 53px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 22px;
}

p {
  font-size: 16.5px;
}

.accordion .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0;
}

:root {
  --main-color-red: #e60000;
  --main-color-yellow: #ffcc00;
  --light-gray: #efefef;
  --white-color: #ffffff;
  --text-color-black: #000000;
  --black-color: #000000;
  --text-color-gray: #8d8d8d;
  --text-color-dark-gray: #2d2d2d;
}

/* @media (max-width: 1280px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }
} */

.pageBlur {
  opacity: 0;
}

.SkeletonContainer {
  display: inline-block;
  height: 450px;
  width: 95%;
  animation: skeleton-loading 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: "0";
}

@keyframes skeleton-loading {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}