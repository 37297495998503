.uploaded_div {
    width: fit-content;
    display: flex;
    justify-self: center;
    flex-direction: column;
    margin-right: 10px;
    position: relative;
    background-color: var(--white-color);
    user-select: none;
}

.uploaded_pdf {
    width: 165px;
    height: 165px;
    overflow: hidden;
    /* border-top: 1px solid black; */
    pointer-events: none;
    border: 1px solid var(--main-color-red);
    border-radius: 10px;
}

.uploaded_pdf img {
    width: 100%;
    height: 100%;
}

.pdfIframe {
    width: 182px;
    height: 180px;
}

.pdfIframe::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.pdfTitle {
    padding: 5px;
    background: var(--main-color);
    width: 165px;
    /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    color: var(--white-color);
}

.deleteIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.certificateCard>span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--main-color-red);
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}