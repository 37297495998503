.confirmBtn {
  font-size: 14px;
  color: #fff;
  background-color: #e60000;
  border-radius: 7px;
  text-align: center;
  margin: 24px 0 0 0;
}
.confirmBtnDiv {
  display: flex;
  justify-content: flex-end;
}
.AddCardDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 0 0;
  padding: 16px 0 24px 0;
}
.AddCardDiv > h3 {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
