.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.main_section {
  padding: 150px 0 80px 0;

  min-height: 100vh;

}

.search_inp {
  /* padding: 20px; */
  /* min-width: 80%; */
}

.searchbar_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.machanicCard_row {
  row-gap: 40px;
  margin-top: 50px !important;
}

.bookingText {
  margin-top: 20px !important;
  text-transform: capitalize;
}

.savebtn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 10px 40px;
  margin-top: 20px;
  font-size: 16px;
}

.dropdown_div {
  font-size: 18px !important;
  padding: 15px 10px !important;
  border-radius: 8px !important;
}

.noDataContainer>div {
  width: unset !important;
}


.container {
  max-width: 75%;
}

@media screen and (max-width:1440px) {
  .container {
    max-width: 85%;
  }
}

@media screen and (max-width:1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width:992px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:767px) {
  .container {
    max-width: 90%;
  }

}

@media screen and (max-width:575px) {
  .mtSM10 {
    margin-top: 10px;
  }
}

@media screen and (max-width:550px) {
  .container {
    max-width: 94%;
  }
}