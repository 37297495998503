.header {
  padding: 10px 0px;
  z-index: 1;

}

.bgWhite {
  background-color: var(--white-color);
  box-shadow: 3px -4px 3px 5px rgb(0 0 0 / 10%)
}

.bgWhite .bars {
  color: var(--black-color);
}

.container {
  max-width: 100%;
}

.logoAndBarsIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 100px;
  height: auto;
}

.logo img {
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.bars {
  color: var(--white-color);
  width: 25px;
}


.mobileDrawer_div {
  width: 100%;
  height: 160px;
  background-color: brown;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileDrawer_div img {
  width: 150px;
}

/* For Icon Count */
.iconBox {
  position: relative;
}

.iconBox .count {
  position: absolute;
  top: -30%;
  left: -20%;
  border-radius: 10px;
  background-color: var(--main-color-red);
  color: var(--white-color);
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}



/* Drawer */
.drawer {
  width: 300px !important;
  z-index: 2;
}

.drawerContainer {
  height: 100vh;
  width: 100%;
}

.drawerLogo {
  width: 120px;
  height: auto;
  margin: 0px auto;
}

/* User Section */
.drawerUserSection {
  height: 175px !important;
  width: 100%;
  background-color: var(--main-color-red);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drawerUserSection .drawerUserImage {
  height: 75px;
  width: 75px;
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
}

.drawerUserSection .drawerUserName {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
  text-transform: capitalize;
}

.drawerUserSection .drawerUserEmail {
  font-size: 14px;
  color: #fff;
}

/* Listing Section */
.drawerList {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.listItem {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 10px 5px 20px;
}

.listItem .listItemText {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #717171;
}


.activeItem {
  background: #ffa36526;
}

.activeItem .listItemText {
  color: var(--main-color-red);
}

.userDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: var(--main-color-red) !important;
  width: 20px;
  height: auto;
}

@media screen and (max-width:540px) {
  .drawer {
    width: 280px !important;
  }

}