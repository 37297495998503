.MainContainer {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 10px;
  padding: 5px 10px;
}

/* Left Icon Css Start */

.search_icon {
}
/* Left Icon Css End */

/* Input Css Start */

.inputtxt {
  display: flex;
  border-radius: 0px;
  font-size: 14px;
  letter-spacing: 1.4px;
  border-radius: inherit;
  background-color: transparent;
  border: none;
  outline: none;

  margin: 0px 10px;
  width: 100%;
  height: 35px;
}

.inputtxt::placeholder {
  color: var(--gray-color);
}

/* Input Css End */

/* Right Botton Css Start  */
.btnContainer {
  background-color: var(--main-color-red);
  color: var(--white-color);
  border: 0px;
  border-radius: 10px;
}

.btnContainer:hover,
.btnContainer:active + .btnContainer,
.btnContainer:checked + .btnContainer,
.btnContainer.active,
.btnContainer:active,
.show > .btnContainer.dropdown-toggle {
  background-color: var(--main-color-red);
}

.btnContainer:active + .btnContainer:focus,
.btnContainer:checked + .btnContainer:focus,
.btnContainer:focus,
.btnContainer.active:focus,
.btnContainer:active:focus,
.show > .btnContainer.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsl(358deg 85% 52% / 28%);
}
/* Right Botton Css End  */
