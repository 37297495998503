.paginationDiv {
  margin-top: 48px;
}

.pageItem > ul > li > button {
  padding: 12px 10px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #222222;
  font-size: 14px;
  line-height: 16px;
  font-family: inter-600;
}
