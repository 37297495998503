.accordion {
    border-radius: 20px !important;
    margin-bottom: 10px;
}

.accordion:hover body {
    background-color: #ebedf0 !important;
}

.body {
    color: var(--text-color-gray);
    font-size: 16px;
}

.heading {
    color: var(--text-color-gray);
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}

@media screen and (max-width:768px) {
    .body {
        font-size: 15px;
    }

    .heading {
        font-size: 18px;
    }
}

@media screen and (max-width:540px) {
    .body {
        font-size: 14px;
    }

    .heading {
        font-size: 16px;
    }
}

@media screen and (max-width:450px) {

    .heading {
        font-size: 15px;
    }
}