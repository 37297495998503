.mainContentDiv {
  padding: 10px 35px;
  text-align: center;
}
.mainContentDiv > h3 {
  color: var(--main-color-red);
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: 700;
}
.mainContentDiv > h5 {
  font-size: 24px;
  margin-bottom: 16px;
}
.inputDiv {
  margin: 20px 0 0 0;
}
.BookBtnDiv {
  display: flex;
  justify-content: center;
}
.BookBtn {
  padding: 9px 54px;
  font-size: 20px;
  background: var(--main-color-yellow);
  border-radius: 12px;
  font-weight: 500;
  margin: 20px 0 16px 0;
  color: #000;
}
