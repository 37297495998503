.cardContainer {
  background-color: var(--white-color);
  border-radius: 10px;
  height: auto;
  width: 100%;
  padding: 0;
  vertical-align: middle;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid #dbdbdb;
  overflow: hidden;
}
.cardRow {
}

.cardMainDiv {
}

.imageContainer img {
  width: 100%;
  height: 300px;
  object-fit: fill;
}
.contentContainer {
  padding: 0px 12px;
  padding-top: 10px;
}

.contentContainer p {
  all: unset;
  color: rgb(38, 38, 38);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.captionContainer {
  height: 36px;
}
.captionContainer p {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.userName {
  font-weight: 600;
  margin-right: 5px;
  /* font-size: 14px;
    line-height: 18px;
    color: rgb(38,38,38); */
}
.createdAt {
  font-size: 10px;
  line-height: 12px;
  color: rgb(142, 142, 142);
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
}

.yellowBtn {
  background-color: var(--main-color-yellow);
  color: var(--text-color-black);
  font-weight: 500;
  padding: 10px 16px;
  border-radius: 10px;
  font-size: 14px;
  text-decoration: none;
  box-shadow: 1px 0px 5px 1px #ffffffb0;
}
